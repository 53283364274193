<template>
  <v-row>
    <v-col sm="12">
      <v-btn small color="success" @click="getLabel" >
        <v-icon left>mdi-download</v-icon>
        Download
      </v-btn>
    </v-col>
    <v-col sm="6">
      <v-card class="mt-3">
        <v-card-title class="align-start">
          Order Details
        </v-card-title>
        <v-card-text class="text-no-wrap text--primary body-1">
          <v-row
            v-for="(value, key) in order"
            :key="key"
            class="pt-2"
            dense
          >
            <v-col sm="4">
              {{ key | titleCase('_') }}
            </v-col>
            <v-col v-if="['created_at', ].includes(key)">
              {{ value | readableDate }}
            </v-col>
            <v-col v-else-if="['user', ].includes(key)">
              <a color="primary" @click="goToUser">{{ value }}</a>
            </v-col>
            <v-col v-else>
              {{ value }}
            </v-col>
            <v-col sm="12">
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col sm="6">
      <v-card class="mt-3">
        <v-card-title class="align-start">
          Recipient Information
        </v-card-title>
        <v-card-text class="text-no-wrap text--primary body-1">
          <v-row
            v-for="(value, key) in recipient"
            :key="key"
            class="pt-2"
            dense
          >
            <v-col sm="4">
              {{ key | titleCase('_') }}
            </v-col>
            <v-col>
              {{ value }}
            </v-col>
            <v-col sm="12">
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTable from '@/components/data-table/DataTable.vue'

import axios from 'axios'

export default {
  components: {
    DataTable,
  },
  data: () => ({
    data: {},
    order: {
      id: null,
      user: null,
      order_number: null,
      market_place: null,
      rate: 0,
      return: 'No',
      tracking_number: null,
      created_at: null,
    },
    recipient: {

    },
    labelUrl: null
  }),
  mounted() {
    document.title = 'Labels';
    
    const { id } = this.$route.params
    this.order_id = id
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.get( `${url}/api/order/${this.order_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        this.data = data
        this.order = {
          id: data.id,
          user: data.user,
          order_number: data.order_number,
          market_place: data.marketplace,
          shipping_service: data.service,
          insurance_price: data.insurance_price ? `$${data.insurance_price.toLocaleString('en-US')}` : '$0',
          rate: data.rate ? `$${data.rate.toLocaleString('en-US')}` : '$0',
          signature: data.tracking_price ? `$${data.tracking_price.toLocaleString('en-US')}` : '$0',
          surcharge: data.surcharge ? `$${data.surcharge.toLocaleString('en-US')}` : '$0',
          total_cost: data.total_price ? `$${data.total_price.toLocaleString('en-US')}` : '$0',
          return: data.order_status_id == 5 ? 'Yes' : 'No',
          tracking_number: data.order_status_id == 5 ? data.return_tracking_id : data.tracking_id,
          created_at: data.created_at,
        }
        this.recipient = {
          first_name: data.first_name,
          last_name: data.last_name,
          company: data.company ,
          address: data.address ,
          address2: data.address2 ,
          city: data.city ,
          province: data.province ,
          postal_code: data.postal_code ,
          country: data.country ,
        }

        this.labelUrl =  data.signedURL
      } catch (error) {
        console.error('error', error)
      }
    },

    async getLabel() {
      const url = process.env.VUE_APP_API_URL
      window.open(`${url}${this.labelUrl}`, "_blank")
    },

    goToUser () {
      this.$router.push({ name: 'accounts-show', params: { id: this.data.user_id} })
    },
  },
}
</script>
